import React from 'react';
import classnames from 'classnames';

import Row, { Props as RowProps } from './Row';
import Column, { Props as ColumnProps } from './Column';

type Background = 'default' | 'transparent' | 'dark' | 'white';

const bgToClassNameMap: Record<Background, string> = {
  default: 'bg-flyblack-gray',
  transparent: 'bg-transparent',
  dark: 'bg-flyblack-darkest-gray',
  white: 'bg-white'
};

interface Props {
  className?: string;
  bg?: Background;
}

interface StaticComponents {
  Row: React.FC<RowProps>;
  Column: React.FC<ColumnProps>;
}

const Card: React.FC<Props> & StaticComponents = ({ className, bg = 'default', children }) => {
  return <div className={classnames('shadow rounded-md', bgToClassNameMap[bg], className)}>{children}</div>;
};

Card.Row = Row;
Card.Column = Column;

export default Card;
